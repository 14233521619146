import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'

const routes = [
  {
    path: '/',
    name: 'Layanan Pinjaman',
    redirect: '/',
    component: Layout,
    children: [
      {
        name: 'Layanan Pinjaman',
        path: '/',
        component: () => import('@/views/Home'),
        meta:{
          title: 'Saku yang nyaman Untuk Anda | Bantusaku',
        }
      }
    ]
  },
  {
    path: '/lender',
    name: 'Pendanaan',
    redirect: '/lender',
    component: Layout,
    children: [
      {
        name: 'Pendanaan',
        path: '/lender',
        component: () => import('@/views/Lender'),
        meta:{
          title: 'Pendana | Bantusaku',
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'Tentang Kami',
    redirect: '/about',
    component: Layout,
    children: [
      {
        name: 'Tentang Kami',
        path: '/about',
        component: () => import('@/views/About'),
        meta:{
          title: 'Tentang Kami | Bantusaku',
        }
      }
    ]
  },
  {
    path: '/faq',
    name: 'FAQ',
    redirect: '/faq',
    component: Layout,
    children: [
      {
        name: 'FAQ',
        path: '/faq',
        component: () => import('@/views/Faq'),
        meta:{
          title: 'FAQ | Bantusaku',
        }
      }
    ]
  },
  {
    path: '/blog',
    name: 'Blog',
    redirect: '/blog',
    component: Layout,
    children: [
      {
        name: 'Blog',
        path: '/blog',
        component: () => import('@/views/Blog'),
        meta:{
          title: 'Blog & Berita | Bantusaku',
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
