import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/icon/index'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import 'animate.css'
import '../node_modules/wowjs/css/libs/animate.css'

import '@/css/common.css'
import '@/css/reSize.scss'
import 'element-ui/lib/theme-chalk/display.css';
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
